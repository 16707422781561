import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layout";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    label: "首页",
    component: Layout,
    redirect: {
      path: "/books"
    },
    children: [
      {
        path: "",
        name: "",
        component: () =>
          import("@/views/home/index" /* webpackChunkName: "首页" */ ),
      },{
        path: "/login",
        name: "login",
        component: () => import("@/views/login" /* 登录 */ ),
      },{
        path: "/accountLogin",
        name: "accountLogin",
        component: () => import("@/views/accountLogin" /* 账号登录 */ ),
      },{
        path: "/bindMobile",
        name: "bindMobile",
        component: () => import("@/views/bindMobile" /* 账号登录 */ ),
      },{
        path: "/register",
        name: "register",
        component: () => import("@/views/register" /* 注册 */ ),
      },{
        path: "/forgetPwd",
        name: "forgetPwd",
        component: () => import("@/views/forgetPwd" /* 忘记密码 */ ),
      },{
        path: "/resetPwd",
        name: "resetPwd",
        component: () => import("@/views/resetPwd" /* 重置密码 */ ),
      }
    ]
  },{
    path: "/books",
    label: "图书",
    component: Layout,
    meta: {
      name: "图书"
    },
    children: [{
      path: "/books",
      label: "图书",
      name: "books",
      component: () =>import("@/views/books/index" /* 图书 */ )
    },{
      path: "/bookDetails",
      label: "图书详情",
      name: "bookDetails",
      component: () =>import("@/views/books/bookDetails/index" /* 图书详情 */ )
    },{
      path: "/showPdf",
      label: "pdf",
      name: "showPdf",
      component: () =>import("@/views/books/bookDetails/showPdf" /* 图书详情 */ )
    },{
      path: "/sampleApplication",
      label: "申请样书",
      name: "sampleApplication",
      component: () =>import("@/views/books/bookDetails/sampleApplication" /* 图书详情 */ )
    }]
  },{
    path: "/news",
    label: "新闻",
    component: Layout,
    meta: {
      name: "新闻"
    },
    children: [{
      path: "",
      label: "新闻",
      name: "news",
      component: () =>import("@/views/news/index" /* 新闻 */ )
    },{
      path: "/newDetails",
      label: "新闻详情",
      name: "Details",
      component: () =>import("@/views/news/newDetails/index" /* 新闻详情 */ )
    }]
  },{
    path: "/numberCloud",
    label: "数字韩云",
    component: Layout,
    meta: {
      name: "数字韩云"
    },
    children: [{
      path: "",
      label: "数字韩云",
      name: "numberCloud",
      component: () =>import("@/views/numberCloud/index" /* 数字瀚云 */ )
    }]
  },{
    path: "/aboutUs",
    label: "关于我们",
    component: Layout,
    meta: {
      name: "关于我们"
    },
    children: [{
      path: "",
      label: "关于我们",
      name: "aboutUs",
      component: () =>import("@/views/aboutUs/index" /* 关于我们 */ )
    }]
  },{
    path: "/schools",
    label: "合作院校",
    component: Layout,
    meta: {
      name: "合作院校"
    },
    children: [{
      path: "",
      label: "合作院校",
      name: "schools",
      component: () =>import("@/views/schools/index" /* 合作院校 */ )
    }]
  },{
    path: "/users",
    label: "个人中心",
    component: Layout,
    meta: {
      name: "个人中心"
    },
    children: [
      {
        path: "",
        label: "个人中心",
        name: "users",
        component: () =>import("@/views/users/index" /* 个人中心主页 */ ),
        children: [{
          path: "/user",
          label: "个人中心",
          name: "user",
          component: () =>
            import("@/views/users/components/user" /* 个人中心 */ )
          },{
            path: "/myCollection",
            label: "我的收藏",
            name: "myCollection",
            component: () =>
              import("@/views/users/components/myCollection" /* 我的收藏 */ )
          },{
            path: "/myCertification",
            label: "教师认证",
            name: "myCertification",
            component: () =>
              import("@/views/users/components/myCertification" /* 教师认证 */ )
          },{
            path: "/myAddress",
            label: "我的地址",
            name: "myAddress",
            component: () =>
              import("@/views/users/components/myAddress" /* 我的地址 */ )
          },{
            path: "/myApply",
            label: "样书申请",
            name: "myApply",
            component: () =>
              import("@/views/users/components/myApply" /* 样书申请 */ )
          },{
            path: "/mySetting",
            label: "安全设置",
            name: "mySetting",
            component: () =>
              import("@/views/users/components/mySetting" /* 安全设置 */ )
          }
        ]
      }
    ]
  },{
    path: "/contact",
    label: "联系我们",
    component: Layout,
    meta: {
      name: "联系我们"
    },
    children: [{
      path: "",
      label: "联系我们",
      name: "contact",
      component: () =>import("@/views/contactUs/index" /* 联系我们 */ )
    }]
  },{
    path: "/bookDownload",
    label: "书目下载",
    component: Layout,
    meta: {
      name: "书目下载"
    },
    children: [{
      path: "",
      label: "书目下载",
      name: "bookDownload",
      component: () =>import("@/views/home/bookDownload/index" /* 书目下载 */ )
    }]
  },{
    path: "/bookRecordSearch",
    label: "书目搜索",
    component: Layout,
    meta: {
      name: "书目搜索"
    },
    children: [{
      path: "",
      label: "书目搜索",
      name: "bookRecordSearch",
      component: () =>import("@/views/home/search/bookRecordSearch" /* 书目搜索 */ )
    }]
  },{
    path: "/bookSearch",
    label: "图书搜索",
    component: Layout,
    meta: {
      name: "图书搜索"
    },
    children: [{
      path: "",
      label: "图书搜索",
      name: "bookSearch",
      component: () =>import("@/views/home/search/bookSearch" /* 图书搜索 */ )
    }]
  },{
    path: "/boutiqueBook",
    label: "精品图书",
    component: Layout,
    meta: {
      name: "精品图书"
    },
    children: [{
      path: "",
      label: "精品图书",
      name: "boutiqueBook",
      component: () =>import("@/views/home/boutiqueBook/index" /* 精品图书 */ )
    }]
  },{
    path: "/advancedSearch",
    label: "高级搜索",
    component: Layout,
    meta: {
      name: "高级搜索"
    },
    children: [{
      path: "",
      label: "高级搜索",
      name: "advancedSearch",
      component: () =>import("@/views/home/search/advancedSearch" /* 高级搜索 */ )
    }]
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash',
  base: '/',
  scrollBehavior: () => ({
    y: 0
  })
})

export default router
