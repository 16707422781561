import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './request/api'
import httpUrl from './request/http';

Vue.prototype.$api = api;
Vue.prototype.$httpURL = httpUrl
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/main.scss'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
