/*
 * @Author: 肖兵
 * @Date: 2021-4-21
 * @Description: 接口统一管理
 */
/**
 * api接口统一管理
 */
import axios from './http';

export default {
	/**发送验证码**/
	getSmsCode (params) {
		return axios.post('/app/sms/smsCode',params);
	},
	/**注册**/
	getRegister (params) {
		return axios.post('/app/user/registerUser',params);
	},
	/**忘记密码验证**/
	getForgetPwdCheck (params) {
		return axios.post('/app/user/checkingMobile',params);
	},
	/**忘记密码修改**/
	getForgetPwdModify (params) {
		return axios.post('/app/user/forgetPasswordUpdate',params);
	},
	/**账号登录**/
	getAccountLogin (params) {
		return axios.post('/app/auth/loginByUsername',params);
	},
	/**获取背景颜色**/
	getSystemColor (params) {
		return axios.get('/app/notice/getSystemColor',params);
	},
	/**轮播图**/ 
	getBanner (params) {
		return axios.get('/app/banner/getDictData',params);
	},
	/**新书推荐**/ 
	getNewBooks (params) {
		return axios.get('/app/books/getNewBooks',params);
	},
	/**公告信息**/
	getNotice (params) {
		return axios.get('/app/notice/getNotice',params);
	},
	/**精品图书分类**/ 
	getBoutiqueCategory (params) {
		return axios.get('/app/books/getBoutiqueCategory',params);
	},
	/**精品图书**/
	getQualityBooks (params) {
		return axios.get('/app/books/getQualityBooks',params);
	},
	/**猜你喜欢**/ 
	getLikereCommendBooks (params) {
		return axios.get('/app/books/getLikereCommendBooks',params);
	},
	/**排行榜**/
	getRankingList (params) {
		return axios.get('/app/books/getListBooks',params);
	},
	/**图书详情**/ 
	getBooksDetails (params) {
		return axios.get('/app/books/getBooksById',params);
	},
	/**相关图书**/ 
	getRelevantBooks (params) {
		return axios.get('/app/books/getRelevantBooks',params);
	},
	/**系列图书**/
	getSeriesBooks (params) {
		return axios.get('/app/books/getSeriesBooks',params);
	},
	/**图书资源分类**/ 
	getBooksResourceType (params) {
		return axios.get('/app/books/getBooksResourceType',params);
	},
	/**分类资源配套**/ 
	getBooksResource (params) {
		return axios.get('/app/books/getBooksResource',params);
	},
	/**浏览/分享/收藏/取消收藏**/ 
	getBooksBrowse (params) {
		return axios.post('/app/books/booksBrowse',params);
	},
	/**图书分类**/ 
	getLabel (params) {
		return axios.get('/app/books/getLabel',params);
	},
	/**图书**/
	getBooks (params) {
		return axios.get('/app/books/getBooks',params);
	},
	/**购买链接**/ 
	getBooksBuy (params) {
		return axios.get('/app/books/getBooksBuy',params);
	},
	/**记录文件下载**/ 
	getDownloads (params) {
		return axios.post('/app/books/downloads',params);
	},
	/**合作院校**/
	getPartners (params) {
		return axios.get('/app/notice/getPartners',params);
	},
	/**关于我们**/ 
	getInformation (params) {
		return axios.get('/app/notice/getInformation',params);
	},
	/**新闻详情**/ 
	getNoticeInfo (params) {
		return axios.get('/app/notice/getNoticeInfo',params);
	},
	/**新闻详情收藏/取消收藏**/
	getNewCollection (params) {
		return axios.post('/app/notice/articleCollection',params);
	},
	/**数字瀚云**/
	getIntroduce (params) {
		return axios.get('/app/introduce/getIntroduce',params);
	},
	/**联系我们**/ 
	getContactUs (params) {
		return axios.get('/app/notice/getContactUs',params);
	},
	/**图书搜索**/ 
	getBookSearch (params) {
		return axios.get('/app/books/bookSearch',params);
	},
	/**书目搜索**/
	getBookListContents (params) {
		return axios.get('/app/contents/getContents',params);
	},
	/**书目分类**/
	getContentsCategory (params) {
		return axios.get('/app/contents/getContentsCategory',params);
	},
	/**书目下载记录**/
	getDownloadContents (params) {
		return axios.get('/app/contents/downloadContents',params);
	},
	/**申请样书**/
	getBooksApply (params) {
		return axios.post('/app/books/booksApply',params);
	},
	/**上传图片**/
	getImgUpload (params) {
		return axios.post('/app/upload/upload',params);
	},
	/**查看用户信息**/
	getUserInfo (params) {
		return axios.get('/app/user/getUserInfo',params);
	},
	/**用户修改**/
	getUpdateUser (params) {
		return axios.post('/app/user/updateUser',params);
	},
	/**收藏列表**/ 
	getMemberCollection (params) {
		return axios.get('/app/user/getMemberCollection',params);
	},
	/**教师认证**/
	getTeacherCertification (params) {
		return axios.post('/app/user/teacherCertification',params);
	},
	/**新增/修改地址**/
	getAddMemberAddress (params) {
		return axios.post('/app/user/addMemberAddress',params);
	},
	/**地址列表**/
	getMemberAddress (params) {
		return axios.get('/app/user/getMemberAddress',params);
	},
	/**样书列表**/ 
	getUserBooksApply (params) {
		return axios.get('/app/user/getBooksApply',params);
	},
	/**删除地址**/ 
	getDelAddres (params) {
		return axios.get('/app/user/delMemberAddressById',params);
	},
	/**查看教师认证**/ 
	getCTeacherCertification (params) {
		return axios.get('/app/user/getTeacherCertification',params);
	},
	/**更换手机号**/ 
	getUpdateMobile (params) {
		return axios.post('/app/user/updateMobile',params);
	},
	/**登陆后修改密码验证**/ 
	getCheckingMobileIsLogin (params) {
		return axios.post('/app/user/checkingMobileIsLogin',params);
	},
	/**登陆后修改密码**/ 
	getUpdatePassword (params) {
		return axios.post('/app/user/updatePassword',params);
	},
	/**登陆检测**/ 
	checkLogin (params) {
		return axios.post('/app/wx/checkLogin',params);
	},
	/**获取公众号二维码**/ 
	getQrCode (params) {
		return axios.get('/app/wx/getQrCode',params);
	},
	/**微信授权**/
	getWXLogin (params) {
		return axios.post('/app/auth/LoginByWeChat',params);
	},
	/**微信解邦**/ 
	getRemoveWechat (params) {
		return axios.post('/app/user/removeWechat',params);
	}, 
	/**友情链接**/ 
	getLinks (params) {
		return axios.get('/app/notice/getLinks',params);
	} 
}
 
 