<template>
  <div class="main-content" :style="{backgroundColor:isSkin}">
    <div class="top">
      <footItem :item="item"></footItem>
    </div>
    <div class="line" :style="{backgroundColor:line}"></div>
    <div class="content-view">
      <div class="logo">
        <img src="../../assets/bookLogo.png" alt="">
      </div>
      <div class="content-title">
        <div class="we">
          <div class="title-left" @click.stop="aboutMethod()">关于我们</div>
          <div>
            <footItem :item="item1"></footItem>
          </div>
        </div>
        <div class="product">
          <div class="title-left">产品中心</div>
          <div>
            <footItem :item="item2"></footItem>
          </div>
        </div>
        <div class="service">
          <div class="title-left">咨询中心</div>
          <div>
            <footItem :item="item3"></footItem>
          </div>
        </div>
        <div class="contact">
          <div class="title-left" @click.stop="contacMethod()">联系我们</div>
          <div>
            <footItem :item="item4"></footItem>
          </div>
        </div>
      </div>
      <div class="code">
          <div class="code-img">
            <img :src="qrCode" alt="">
          </div>
          <div class="code-title">官方微信</div>
      </div>
    </div>
    <div class="copyright">版权所有: 北京科瀚伟业教育科技有限公司  地址：北京市海淀区北三环西路43号青云当代大厦21层2102 
      <div class="jin">
        备案号
        <a href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px; color:#FFF;" target="_blank">京ICP备13039988号-1</a>
      </div>
      <div class="gan">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030744" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../../assets/gongan.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#FFF;">京公网安备 11010802030744号</p ></a >
		 	</div>
      </div>
  </div>
</template>

<script>
import footItem from './footItem-box'
export default {
  components:{footItem},
  data() {
    return {
      qrCode:'',
      item:[],
      item1:[{title:'公司领导'},{title:'公司简介'},{title:'公司历史'},{title:'公司荣誉'},{title:'公司介绍'},{title:'企业文化'},{title:'隐私政策'},{title:'用户协议'}],
      item2:[{title:'图书'},{title:'书目下载'}],
      item3:[{title:'公司新闻'},{title:'活动消息'},{title:'数字瀚云'},{title:'专业服务建设'}],
      item4:[{title:'读者服务'},{title:'经销商服务'},{title:'人才招聘'},{title:'作译者服务'},{title:'法律声明'},{title:'盗版举报'}],
    }
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },
    line(){
		  return this.$store.state.isZSkin
    },
  },
  created () {
    this.getLinks()
    this.getNotice()
  },
  methods:{
    /**友情链接**/ 
    getLinks(){
      var that = this
      that.$api.getLinks().then(res=>{
        if (res.data.code == 0) {
          res.data.data.forEach((item,index)=>{
            that.item.push({
              title:item.noticeTitle,
              links:item.links,
              noticeType:item.noticeType
            })
          })
        }else{
          that.item = []
        }
      })
    },
    /**获取二维码**/ 
    getNotice(){
			var that = this
			that.$api.getNotice({params:{noticeType:6,pageNum:1,pageSize:1}}).then(res=>{
				if (res.data.code == 0) {
          that.qrCode = res.data.data.list[0].img
				}else{
					that.qrCode
				}
			})
    },
    aboutMethod(){
      this.$router.push('/aboutUs');
      this.$store.commit('changClick',1)
	    sessionStorage.setItem('changWe',0)
	    this.$store.commit('changWe',0)
    },
    contacMethod(){
      this.$router.push('/contact');
      this.$store.commit('changClick',1)
	    sessionStorage.setItem('changLx',0)
	    this.$store.commit('changLx',0)
    }
  }
}
</script>

<style scoped lang="scss">
.main-content{
    width: 100%;
    height: 370px;
    background-color: saddlebrown;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top{
      height: 63px;
    }
    .line{
      width: 100%;
      height: 2px;
    }
    .content-view{
      width: 1200px;
      display: flex;
      display: -webkit-flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 30px;
      .logo{
        width: 23%;
      }
      .content-title{
        width: 62%;
        .we{
          height: 20px;
          display: flex;
          justify-items: center;
          align-items: center;
          .title-left{
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            padding-right: 10px;
            cursor: pointer;
          }
        }
        .product{
          height: 20px;
          display: flex;
          justify-items: center;
          align-items: center;
          margin-top: 30px;
          .title-left{
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            padding-right: 10px;
          }
        }
        .service{
          height: 20px;
          display: flex;
          justify-items: center;
          align-items: center;
          margin-top: 30px;
          .title-left{
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            padding-right: 10px;
          }
        }
        .contact{
          height: 20px;
          display: flex;
          justify-items: center;
          align-items: center;
          margin-top: 30px;
          .title-left{
            font-size: 18px;
            line-height: 25px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            padding-right: 10px;
            cursor: pointer;
          }
        }
      }
      .code{
        width: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .code-img{
          margin-left: 15%;
          width: 152px;
          height: 152px;
          > img{
            width: 100%;
            height: 100%;
          }
        }
        .code-title{
          font-size: 14px;
          line-height: 20px;    
          display: flex;
          align-items: center;
          color: #FFFFFF;
          margin-left: 15%;
          margin-top: 10px;
        }
      }
    }
    .copyright{
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      margin-top: 30px;
      display: flex;
      justify-content: left;
      align-items: center;
      .jin{
        margin-left: 20px;
        color: #FFFFFF;
      }
      .gan{
        margin-left: 20px;
        color: #FFFFFF;
      }
    }
}
</style>