<template>
  <div class="main-content">
    <div class="logo">
      <img v-if="isClick=='#FFF'" src="../../assets/bookLogo.png" alt="">
      <img v-if="isClick=='#333'" src="../../assets/bookLogo1.png" alt="">
    </div>
    <div class="itme">
      <div class="itme-left">
        <div :style="{color:isClick}" @click="switchMethod(index)" class="title" v-for="(item,index) in searchList" :key="index">{{item}}</div>
      </div>
      <div class="item-right">
        <div class="search-bg" :style="{backgroundColor:isSearch}">
          <input v-model="searchTitle" type="text" placeholder="输入关键字" @keyup.enter="enterTextBox" class="input-title">
          <div class="search-btn" @click="searchMethod()">
            <img src="../../assets/search/search.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="user">
        <div class="no-login" v-if="!isLogin">
          <div :style="{color:isClick}" @click="login()">登录</div>
          <div :style="{color:isClick}" class="register" @click="register()">注册</div>
        </div>
        <div class="yes-login" v-else-if="isLogin">
          <div>
            <el-image class="headImg" style="border-radius: 50%;" :src="HeadImg"></el-image>
            </div>
          <el-dropdown trigger="click" @command="handleCommand" class="dropdown-list">
              <span class="el-dropdown-link">{{Nickname}}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown">
                <el-dropdown-item command="个人中心">个人中心</el-dropdown-item>
                <el-dropdown-item command="我的收藏">我的收藏</el-dropdown-item>
                <el-dropdown-item command="样书申请">样书申请</el-dropdown-item>
                <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
          <div class="teachers" v-show="isTeachers">
            <img src="../../assets/home/teachers.png" alt="">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
export default {
  data() {
    return {
      nickname:'帅的科幻的男人',
      searchTitle:'', 
      searchList:['首页','图书','新闻','数字瀚云','关于我们'],
    }
  },
  created(){
    
  },
  computed:{
    Nickname(){
      if (this.$store.state.isNickname == '') {
        return sessionStorage.getItem('userName')
      }else{
        return this.$store.state.isNickname
      }
    },
    HeadImg(){
      if (this.$store.state.isHeadImgUrl == '') {
        return 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3757239321,1175359126&fm=26&gp=0.jpg'
      }else{
        return this.$store.state.isHeadImgUrl
      }
    },
    isLogin(){
      if (this.$store.state.isLogin==1) {
        return true
      }else{
        return false
      }
    },
    isTeachers(){
      if (this.$store.state.isTeachers==2) {
        return true
      }else{
        return false
      }
    },
    isClick(){
      if (this.$store.state.isClick==0) {
        return "#FFF"
      }else if (this.$store.state.isClick==1) {
        return "#333"
      }
    },
    isSearch(){
      if (this.$store.state.isClick==0) {
        return "rgba(255, 255, 255, 0.5)"
      }else if (this.$store.state.isClick==1) {
        return "rgba(206, 206, 206, 0.4)"
      }
    },
  },
  methods:{
    enterTextBox(event){
      if (event.keyCode == 13) {
        this.$router.push({path:'/bookSearch',query:{title:this.searchTitle}});
        this.$store.commit('changClick',1)
		    bus.$emit('message', this.searchTitle);
		    this.searchTitle = ''
     }
    },
    /**搜索按钮**/ 
    searchMethod(){
        this.$router.push({path:'/bookSearch',query:{title:this.searchTitle}});
        this.$store.commit('changClick',1)
		    bus.$emit('message', this.searchTitle);
        this.searchTitle = ''
    },
    /**切换按钮**/ 
    switchMethod(index){
      if (index==0) {
        this.$router.push('/');
        this.$store.commit('changClick',0)
      }else if (index==1) {
        this.$router.push('/books');
        this.$store.commit('changClick',1)
      }else if (index==2) {
        this.$router.push('/news');
        this.$store.commit('changClick',1)
        this.$store.commit('changNew',0)
        sessionStorage.setItem('newLeft',0)
      }else if (index==3) {
        this.$router.push('/numberCloud');
        this.$store.commit('changClick',0)
      }else if (index==4) {
        this.$router.push('/aboutUs');
        this.$store.commit('changClick',1)
        this.$store.commit('changWe',0)
		    sessionStorage.setItem('changWe',0)
      }
    },
    /**下拉列表按钮**/ 
    handleCommand(index){
      if (index=='个人中心') {
        this.$router.push('/user');
        this.$store.commit('changClick',1)
        this.$store.commit('changLeft',0)
      }else if (index=='我的收藏') {
        this.$router.push('/myCollection');
        this.$store.commit('changClick',1)
        this.$store.commit('changLeft',1)
      }else if (index=='样书申请') {
        this.$router.push('/myApply');
        this.$store.commit('changClick',1)
        this.$store.commit('changLeft',4)
      }else if (index=='退出登录') {
        this.$router.push('/login');
        this.$store.commit('changClick',1)
        sessionStorage.setItem('token','')
        sessionStorage.setItem('userNickName','')
        sessionStorage.setItem('userHeadImg','')
        sessionStorage.setItem('Teachers','')
        sessionStorage.setItem('bindWechat','')
        this.$store.commit('changLogin',0)
      }
    },
    /**登录**/
    login(){
      this.$router.push('/login');
      this.$store.commit('changClick',1)
    },
    /**注册**/
    register(){
      this.$router.push('/register');
      this.$store.commit('changClick',1)
    }
  }
}
</script>

<style scoped lang="scss">
.main-content{
    height: 80px;
    background-color:red 0px;
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 9999;
    .logo{
      width: 19%;
      height: 60px;
      display: flex;
      justify-items: right;
      align-items: center;
      position: relative;
      > img{
        position: absolute;
        right: 0;
      }
    }
    .itme{
      width: 65%;
      height: 60px;
      display: flex;
      justify-items: center;
      align-items: center;
      .itme-left{
        width: 54%;
        height: 60px;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-left: 9%;
        .title{
          text-align: center;
          font-size: 18px;
          color: #FFF;
          width: calc(100% / 5);
          cursor: pointer;
        }
      }
      .item-right{
        width: 30%;
        height: 60px;
        display: flex;
        justify-items: center;
        align-items: center;
        margin-left: 8%;
        .search-bg{
          width: 80%;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-items: center;
          align-items: center;
          margin-left: 5%;
          .input-title{
            width: 80%;
            height: 40px;
            line-height: 40px;
            background: rgba(255, 255, 255, 0);
            margin-left: 5%;
            border:none;//去除边框
            outline:none; //去点击蓝色边框
            color: #666;
            font-size: 15px;
          }
          .search-btn{
            width: 10%;
            margin-left: 5%;
            cursor: pointer;
          }
        }
      }
    }
    .user{
      width: 16%;
      height: 60px;
      display: flex;
      justify-items: center;
      align-items: center;
      .no-login{
        display: flex;
        justify-items: center;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        cursor: pointer;
        .register{
          margin-left: 30px;
        }
      }
      .yes-login{
        height: 60px;
        width: 100%;
        display: flex;
        justify-items: center;
        align-items: center;
        .headImg{
          width: 40px;
          height: 40px;
        }
        .dropdown-list{
          margin-left: 10px;
          cursor: pointer;
          .dropdown{
            background-color: red;
          }
        }
        .teachers{
          margin-left: 10px;
        }
      }
    }
}
</style>