<template>
  <div class="content">
      <div class="content-title" v-for="(item,index) in item" :key="index">
          <div v-if="index!=0" class="line" :style="{backgroundColor:line}"></div>
          <div @click.stop="cilckMethod(item.title,item.noticeType,item.links)" class="title">{{item.title}}</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    computed:{
        line(){
            return this.$store.state.isZSkin
        },
    },
    methods:{
        cilckMethod(index,ID,url){
            if (ID == 7) {
                if (url != null) {
                    window.open(url)
                }
            }else if (index=='公司领导') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',0)
				sessionStorage.setItem('changWe',0)
            }else if (index=='公司简介') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',1)
				sessionStorage.setItem('changWe',1)
            }else if (index=='公司历史') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',2)
				sessionStorage.setItem('changWe',2)
            }else if (index=='公司荣誉') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',3)
				sessionStorage.setItem('changWe',3)
            }else if (index=='公司介绍') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',4)
				sessionStorage.setItem('changWe',4)
            }else if (index=='企业文化') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',5)
				sessionStorage.setItem('changWe',5)
            }else if (index=='隐私政策') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',7)
				sessionStorage.setItem('changWe',7)
            }else if (index=='用户协议') {
                this.$router.push('/aboutUs');
                this.$store.commit('changClick',1)
                this.$store.commit('changWe',6)
				sessionStorage.setItem('changWe',6)
            }else if (index=='图书') {
                this.$router.push('/books');
                this.$store.commit('changClick',1)
            }else if (index=='书目下载') {
                this.$router.push('/bookDownload');
                this.$store.commit('changClick',1)
            }else if (index=='公司新闻') {
                this.$router.push('/news');
                this.$store.commit('changClick',1)
                this.$store.commit('changNew',1)
                sessionStorage.setItem('newLeft',1)
            }else if (index=='活动消息') {
                this.$router.push('/news');
                this.$store.commit('changClick',1)
                this.$store.commit('changNew',2)
                sessionStorage.setItem('newLeft',2)
            }else if (index=='数字瀚云') {
                this.$router.push('/numberCloud');
                this.$store.commit('changClick',0)
            }else if (index=='专业服务建设') {
                this.$router.push('/schools');
                this.$store.commit('changClick',0)
            }else if (index=='读者服务') {
                this.$router.push('/contact');
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',0)
                this.$store.commit('changLx',0)
            }else if (index=='经销商服务') {
                this.$router.push('/contact');
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',1)
                this.$store.commit('changLx',1)
            }else if (index=='人才招聘') {
                this.$router.push('/contact');
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',2)
                this.$store.commit('changLx',2)
            }else if (index=='作译者服务') {
                this.$router.push('/contact');
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',3)
                this.$store.commit('changLx',3)
            }else if (index=='法律声明') {
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',4)
                this.$store.commit('changLx',4)
				this.$router.push('/contact');
            }else if (index=='盗版举报') {
                this.$store.commit('changClick',1)
				sessionStorage.setItem('changLx',5)
                this.$store.commit('changLx',5)
				this.$router.push('/contact');
            }
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    display: flex;
    justify-items: center;
    align-items: center;
    .content-title{
        display: flex;
        justify-items: center;
        align-items: center;
        margin-left: 10px;
        line-height: 63px;
        .title{
            margin-left: 10px;
            font-size: 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.9);
        }
        .line{
            width: 2px;
            height: 15px;
            background-color: yellow;
        }
    }
}
</style>