import { render, staticRenderFns } from "./footView-box.vue?vue&type=template&id=4556da11&scoped=true&"
import script from "./footView-box.vue?vue&type=script&lang=js&"
export * from "./footView-box.vue?vue&type=script&lang=js&"
import style0 from "./footView-box.vue?vue&type=style&index=0&id=4556da11&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4556da11",
  null
  
)

export default component.exports