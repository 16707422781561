/*
 * @Author: 肖兵
 * @Date: 2021-4-21
 * @Description: axios封装
 */

/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import {Message,Loading} from 'element-ui'
import store from '@/store'
const baseUrl = 'https://bookadmin.abook.cn/prod-api'
const service = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers:{
      'Conter-Type':'application/json'
    }
})
 /**添加请求拦截器**/
service.interceptors.request.use(config => {
	/**在发送请求之前做些什么 token**/
	if (sessionStorage.getItem('token')) {
		config.headers.common['token'] = `${sessionStorage.getItem('token')}`
	}
    console.log('token:',sessionStorage.getItem('token'));
    showFullScreenLoading()
	return config;
}, error => {
	/**对请求错误做些什么**/
	return Promise.reject(error);
});

/**加载动画**/
/**定义loading变量**/
let loading
/**使用Element loading-start 方法**/
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0)'
    })
}
/**使用Element loading-close 方法**/
function endLoading() {
    loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}
export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
        needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

/**添加响应拦截器**/
service.interceptors.response.use(response => {
	/**对响应数据做点什么**/
    tryHideFullScreenLoading()
    if (response.status === 200) {
        if (response.data.code !== 0) {
			if(response.data.code == 4002 || response.data.code == 4001){
				sessionStorage.setItem('token','')
				this.$store.commit('changLogin',0)
                // Message({
                //     showClose: true,
                //     message: response.data.msg,
                //     type: 'error'
                // })
			}else{
                Message({
                    showClose: true,
                    message: response.data.msg,
                    type: 'error'
                })
            }
        }
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}, error => {
	tryHideFullScreenLoading()
    if (error.response.status) {
        switch (error.response.status) {
            /**401: 未登录,跳转登录页面**/
            case 401:
                Message({
                    showClose: true,
                    message: '请登录',
                    type: 'error'
                })
                break;
            /**403 token过期,清除本地token**/
            case 403:
                Message({
                    showClose: true,
                    message: '登录信息已过期，请重新登录',
                    type: 'error'
                })
                sessionStorage.setItem('token','')
                this.$store.commit('changLogin',0)
                break;
            /**404请求不存在**/
            case 404:
                Message({
                    showClose: true,
                    message: '登录信息已过期，请重新登录',
                    type: 'error'
                })
                sessionStorage.setItem('token','')
                this.$store.commit('changLogin',0)
                break;
            /**其他错误，直接抛出错误提示**/
            default:
                Message({
                    showClose: true,
                    message: error.response.data.message,
                    type: 'error'
                })
        }
        return Promise.reject(error.response);
    }
});

export default service;


