import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const state = {
	isLogin:sessionStorage.getItem('token')?1:0,
	isSkin:sessionStorage.getItem('Skin')?sessionStorage.getItem('Skin'):'#CAB79A',
	isZSkin:sessionStorage.getItem('ZSkin')?sessionStorage.getItem('ZSkin'):'#B79F7B',
	isClick:0,
	isLeft:sessionStorage.getItem('navLeft')?sessionStorage.getItem('navLeft'):0,
	isWeIndex:sessionStorage.getItem('changWe')?sessionStorage.getItem('changWe'):0,
	isLxIndex:sessionStorage.getItem('changLx')?sessionStorage.getItem('changLx'):0,
	isNewIndex:sessionStorage.getItem('newLeft')?sessionStorage.getItem('newLeft'):0,
	isSearch:0,
	isNickname:sessionStorage.getItem('userNickName')?sessionStorage.getItem('userNickName'):'',
	isHeadImgUrl:sessionStorage.getItem('userHeadImg')?sessionStorage.getItem('userHeadImg'):'',
	isTeachers:sessionStorage.getItem('Teachers')?sessionStorage.getItem('Teachers'):''
}
const mutations = {
	changLogin(state, data) {
		state.isLogin = data
	},
	changNickname(state, data) {
		state.isNickname = data
	},
	changHeadImgUrl(state, data) {
		state.isHeadImgUrl = data
	},
	changTeachers(state, data) {
		state.isTeachers = data
	},
	changSkin(state, data) {
		state.isSkin = data
	},
	changZSkin(state, data) {
		state.isZSkin = data
	},
	changClick(state, data) {
		state.isClick = data
	},
	changLeft(state, data) {
		state.isLeft = data
	},
	changWe(state, data) {
		state.isWeIndex = data
	},
	changLx(state, data) {
		state.isLxIndex = data
	},
	changNew(state, data) {
		state.isNewIndex = data
	},
	changSearch(state, data) {
		state.isSearch = data
	},
}
const getters = {
	
}
const store = new Vuex.Store({
	state,
	mutations,
	getters
})
export default store
