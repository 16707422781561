<template>
  <div class="main">
      <navigationBar></navigationBar>
      <div class="main-box">
        <transition :name="transitionName">
            <appMain></appMain>
        </transition>
        <div class="service" @click.stop="cilckMethod()" id="box1">
            <img src="../../assets/contactService.png" @click.stop="cilckMethod()" alt="">
        </div>
      </div>
      <footView></footView>
  </div>
</template>

<script>
import navigationBar from './app_navigationBar'
import appMain from './app_main'
import footView from '../tools/footView-box'
export default {
    components:{navigationBar,appMain,footView},
    data() {
        return {
          transitionName: "",
          
        };
    },
    mounted(){
    // window.onload=function(){
    //           //使div可以随鼠标移动
    //           //获取box1
    //           var box1=document.getElementById("box1");
    //           document.onmousedown = function(){
    //                 document.onmousemove=function(event){
    //     //这里event事件对象，当事件响应函数被触发时，浏览器每次都会将事件对象
    //     //作为实参传递，就算不写形参也会传递，但是写了之后，更容易调用
    //                 //获取滚动条的距离
    //                 var st=document.documentElement.scrollTop;
    //                 console.log(st);
    //                 //获取鼠标的坐标
    //                 var left=event.clientX;
    //                 var top=event.clientY;
    //                 box1.style.left=left+st+"px";
    //                 box1.style.top=top+st+"px";
    //                 document.onmouseup = function(){
    //                       document.onmousemove = null;
    //                       document.onmouseup = null;
                          
    //                   };
    //             }
    //           }
            
    //         }
    },
    methods:{
      

      cilckMethod(){
        window.location.href="tencent://message/?uin=756645564&Site=gxlinzi.cn35so.cn&Menu=yes";
      }
    }
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  height: 100%;
  .main-box{
    position: relative;
    .service{
      position:fixed;
      z-index: 1000;
      right: 60px;
      top: 40%;
      width: 100px;
      height: 100px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>